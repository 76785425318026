import React from "react";
import styled from "styled-components";

const Form = ({ onSubmit, children }) => <Container onSubmit={onSubmit}>
    {children}
</Container>;

const Container = styled.form`

`;

export default Form;