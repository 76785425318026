import React from "react";
import styled from "styled-components";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { Form, FormField, Submit } from "@common/admin/components/shared/form";

const Login = () => {
	return (
		<Container>
			<Title>Logg inn</Title>
			<Formik
				onSubmit={(values, actions) => {
					console.log(values);
				}}
				initialValues={{
					email: "",
					password: ""
				}}
				render={({
					values,
					touched,
					errors,
					setFieldValue,
					handleSubmit,
					handleChange,
					handleBlur,
					isValid,
					isSubmitting
				}) => (
					<Form onSubmit={handleSubmit}>
						<FieldRow>
							<FormField
								type="text"
								name="email"
								label={{ text: "Epost" }}
								value={values.email}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
						</FieldRow>
						<FieldRow>
							<FormField
								type="text"
								name="password"
								label={{ text: "Passord" }}
								value={values.password}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
						</FieldRow>
						<FieldRow>
							<Submit text="Logg inn" />
						</FieldRow>
					</Form>
				)}
			/>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-flow: column;
	max-width: 100%;
	margin: 72px auto 0 auto;
	padding: 24px;

	${({ theme }) => theme.media.greaterThan("medium")`
        max-width: 408px;
    `};
`;

const Title = styled.h1`
	margin: 0 0 36px 0;
	text-align: center;
`;

const FieldRow = styled.div`
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	margin-bottom: ${({ margin }) => (margin ? margin : "18px")};

	input {
		background: #333;
		border-radius: 0;
		border: 0;
	}
`;

export default Login;
