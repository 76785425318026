import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Select from "react-select";

const FormField = ({ label, error, width, ...rest }) => {
	return (
		<Container type={rest.type} width={width && width}>
			<Label type={rest.type} htmlFor={rest.name}>
				{label.text}
			</Label>
			{rest.type === "select" ? (
				<ReactSelect
					className="react-select-container"
					classNamePrefix="react-select"
					instanceId={rest.name}
					{...rest}
					styles={{
						container: provided => ({
							...provided,
							width: "100%"
						})
					}}
				/>
			) : (
				<Input name={rest.name} id={rest.name} {...rest} />
			)}
			{error ? (
				<InfoContainer type={rest.type}>
					<Info type="error">{error}</Info>
				</InfoContainer>
			) : null}
		</Container>
	);
};

const Container = styled(({ className, children }) => <div className={className}>{children}</div>)`
	display: flex;
	flex-flow: ${({ type }) => (type === "checkbox" ? "row wrap" : "column")};
	align-items: ${({ type }) => (type === "checkbox" ? "center" : "flex-start")};
	justify-content: ${({ type }) => (type === "checkbox" ? "flex-start" : "center")};
	width: ${({ width }) => (width ? width : "100%")};

	label {
		order: ${({ type }) => (type === "checkbox" ? "1" : "0")};
	}
`;

const Label = styled(({ className, id, htmlFor, children }) => (
	<label className={className} htmlFor={htmlFor}>
		{children}
	</label>
))`
	flex: 1;
	margin-bottom: ${({ type }) => (type !== "checkbox" ? "6px" : "0px")};
	font-size: 12px;
	font-weight: 600;
	text-transform: uppercase;
`;

const Input = styled.input`
	display: flex;
	align-items: center;
	width: 100%;
	height: 48px;
	padding: 0 12px;
	border-radius: 4px;
	border: 1px solid #ccc;

	&[type="checkbox"] {
		width: auto;
		margin-right: 9px;
	}
`;

const ReactSelect = styled(Select)`
	position: relative;
	width: 100%;
	.react-select-container {
		position: relative;
		width: 100%;
	}
	.react-select__control {
		align-items: center;
		background-color: hsl(0, 0%, 100%);
		border-color: #ccc;
		border-radius: 4px;
		border-style: solid;
		border-width: 1px;
		cursor: default;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		min-height: 38px;
		outline: 0 !important;
		position: relative;
		transition: all 100ms;
		box-sizing: border-box;
	}

	.react-select__value-container {
		align-items: center;
		display: flex;
		flex: 1;
		flex-wrap: wrap;
		padding: 2px 8px;
		-webkit-overflow-scrolling: touch;
		position: relative;
		overflow: hidden;
	}
`;

const InfoContainer = styled.div`
	display: block;
	width: 100%;
	order: 3;
	margin-top: ${({ type }) => (type === "checkbox" ? "0" : "6px")};
`;

const Info = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 6px 9px;
	border-radius: 4px;
	background: ${({ type }) => (type && type === "error" ? "papayawhip" : "dodgerblue")};
	color: #000;
	font-size: 13px;
`;

FormField.propTypes = {
	type: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.any,
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func.isRequired,
	error: PropTypes.string,
	label: PropTypes.shape({
		text: PropTypes.string.isRequired
	}).isRequired,
	width: PropTypes.string,
	options: (props, propName) => {
		if (props.type === "select" && !props[propName]) {
			return new Error("You must supply an array of options for select inputs.");
		}
	}
};

export default FormField;
