import React from "react";
import styled from "styled-components";

const Submit = ({ text, ...rest }) => (
	<Button {...rest} type="submit">
		{text}
	</Button>
);

const Button = styled.button`
	${({ theme }) => theme.styles.button};
    flex: 1;
`;

export default Submit;
